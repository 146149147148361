    <template>
     <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center" > 
      <div class = "container"  v-if="parsingtoken==false">

        <div id="signinpage" v-if="pageloading==false"> 
         <p class="logo"><a href="/"><img src="@/static/img/flowe.png" class="imglogo"></a></p>

         <div class="responsepanel mt-3 text-center">
          
          <div class="response text-danger" v-if="error=='sessionexpired'" >Your session has expired. To continue, please sign in again.</div>
          
          
        </div>

        <template v-if="samlerror=='signinerror'">

            <div class="pagetitle">
          <h2 class = "text-center">Sign in error</h2> 
        </div>   
          <div class="text-center pagetitle">
          <p>Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists.</p>
          <p>&nbsp;</p>
          <p><a class="text-primary" href="/sign-in?change=1">Click here to go back sign-in</a></p>
          </div>

          </template>

          <template v-if="samlerror == 'usernotmember'">     

          <div class="pagetitle">
          <h2 class = "text-center">Unknown account</h2> 
        </div>   
          <div class="text-center pagetitle">
          <p>The SSO account you are signed in with is not registered in Flowe. Contact your IT administrator to enable this account, or click the link below to go back to the sign-in page to try again with a different account.</p>
          <p>Browsers handle SSO accounts differently, and you may need to sign out of your current SSO account in your browser in order to be able to use a different SSO account.</p>
          <p>&nbsp;</p>
          <p><a class="text-primary" href="/sign-in?change=1">Click here to go back sign-in</a></p>
          </div>
           

          </template>


        

        <div class="pagetitle" v-if="!idp.domain && !hasdomain && samlerror == ''">
          <h2 class = "text-center">Sign in with SSO</h2> 
        </div>        

        <div class="form_wrapper" v-if="!idp.domain && !hasdomain && samlerror == ''">

          <form onsubmit="return false;">

            <!-- <div class="text-danger" v-if="signin.signinlocked">Account has been temporarily locked. Please try again after 15 minutes.</div> -->

            <div class="form-group">

              <div v-if="hasdomain && error == 'notcorporate'" class="text-center">
          <div class="text-danger ">Domain do not have a valid identity provider.</div>
        </div>

        <div v-if="error == 'notprovisioned'" class="text-center">
          <div class="text-danger ">This domain is not associated with any organization in Flowe. Please contact your organization administrator.</div>
        </div>


              <label>Organization domain</label>
              <input type="text" class="form-control tabable" aria-describedby="emailHelp" placeholder="domain.com" autocomplete="off" v-model="ssoemail" tabindex=1 autocorrect="off" autocapitalize="off" spellcheck="false">                
              <div class="text-danger" v-if="error == 'emailinvalid'">Please enter a valid email address</div>
              <p class="text-danger" v-if="error == 'notcorporate'">This domain is not associated with any organization in Flowe. Please contact your organization administrator.</p>
              <p class="text-danger" v-if="error == 'notdomain'">Please enter a valid domain.</p>

            </div>

            <div class="form-group">
              <button id="signinwithemail" v-if="status=='idle'" class="btn btn-block btn-lg btn-primary tabable" v-on:click="ssopresignin()" tabindex=4 >Continue</button>
            </div>
            
             <div class="form-group">
            <p class="text-center mt-2">

                <router-link :id="'signin'" :to="'/sign-in?change=1'" class="actionwarning tabable">Sign in using a regular account</router-link>
              </p>
          </div>

          </form>




      </div>


    </div>
  </div>


</div>
</template>	
<style type="text/css" scoped>
:root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
  overflow:hidden;
}

h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

button[type="nostyle"]:focus {outline:  dotted 1px;}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

p.logo .imglogo{ 
  width: 200px; 
}
p.logo{
  text-align: center;
  width: 100%;
  margin: auto;
}

input[type="checkbox"]{
        outline:0;
        border: 1px solid #000 !important;
    }

</style>

<script type="text/javascript"></script>
<script>

  import Signinapi from '@/services/api/signin'
  import Userapi from '@/services/api/users'

  import store from '@/store'
  import auth from '@/cognito.js';

  import Cognitosettings from '@/store/cognito-settings';
  import UserInfoStore from '@/store/cognito-info-store';

  //nwjC8b2Q12YqHCRx70f4BfYw
  export default{
    data(){
      return{

          pageloaded:true,
          sessionisvalid:false,
          error:"",
          ssoemail:"",
          status:"idle",
          idp:{},
          userPoolId: Cognitosettings.state.userpool,
          userInfo: UserInfoStore.state.cognitoInfo,
          jwtinfo : {},
          parsingtoken : false,
          pageloading:true,
          samlerror:""
          }
        },
        computed: {

          hasdomain(){
            return this.$route.params.idp == '' || this.$route.params.idp == null ? false : true
          }
    
        },
        filters: {},
         watch:{

          },

        mounted(){



          if( this.$route.query.error ){
            this.samlerror = this.$route.query.error + ''
          }

          

         

          if(  auth.auth.getSignInUserSession() && auth.auth.getSignInUserSession().getRefreshToken() && auth.auth.getSignInUserSession().getRefreshToken().refreshToken ){ 

            var refreshtoken = auth.auth.getSignInUserSession().getRefreshToken().refreshToken
            auth.auth.refreshSession( refreshtoken )
          }

          //console.log( refreshtoken )

          this.pageloading = false

          document.title = 'Flowe | Sign in'
          $('#preloader').addClass('hide')          


          if (!auth.auth.isUserSignedIn()) {

            if( window.location.href.includes("ssosignin") ){

              this.parsetoken()
              this.parsingtoken = true

            }else{

              if( this.$route.params.idp ){

                this.loadidp( this.$route.params.idp )
                //auth.auth.getSession()
              }

            }

            //alert('not auth')
           /* next({
              path: '/login',
              query: { redirect: to.fullPath }
              });*/
            

          }else{

            //alert('y')

            var jwt = Signinapi.getjwtUserInfo()

            //console.log(jwt)

            jwt.then( function(response){

             self.jwtinfo = response

            })


            //if( this.$route.params.idp ){
            //  this.loadidp( this.$route.params.idp )
            //}
            
          }            


        },
          methods: {

            parsetoken(){

              var currUrl = window.location.href;
              auth.auth.parseCognitoWebResponse(currUrl);

            },

            loadidp( idp ){


              let self = this

              Signinapi.Loadidp( idp ).then( function(response){

                if( response.data ){

                  if( response.data.ok  ){

                    // invokes a call to cognito for the idp
                    //auth.auth.getSession()

                    //loads the idp url 
                    location.href=response.data.idp.idp

                  }else{

                    self.error = response.data.error

                  }


                }
              })

            },

            ssopresignin(){

              

              this.error = ""

              let self = this

              Signinapi.Checkifcorporate( { 'email': this.ssoemail, 'action':'checkifsso' } ).then( function(response){

                if( response.data ){


                  if( response.data.ok  ){

                    self.idp = response.data.idp
                   // self.loadidp( response.data.idp )

                    //self.$router.push(  )
                    self.pageloading = true
                    
                    location.href = '/sign-in-sso/'+response.data.idp.domain

                  }else{

                    self.pageloading = false

                    self.error = response.data.error

                  }


                }
              })

            }
          },
          components : {}
        }
      </script>